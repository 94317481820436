import styled, { css } from 'styled-components';
import { screen } from '@styles/mixins/breakpoints';

const StyledInformationList = styled.div`
  padding-bottom: 120px;
`;

const StyledFactsFooter = styled.div`
  display: flex;
  max-width: 1088px;
  flex-direction: column;
  padding: 0 32px 200px;
  margin: 0 auto;
  font: 24px/36px "gt-america-extended-bold", Arial, sans-serif;
  text-align: center;

  ${screen.l(css`
    padding: 0 16px 100px;
    font-size: 20px;
    line-height: 30px;
  `)}

  p:not(:last-child) {
    padding-bottom: 50px;
  }
`;

export default {
  StyledInformationList,
  StyledFactsFooter,
};
