import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import InformationList from '@components/InformationList';
import TextHeading from '@components/TextHeading';

import { css } from 'styled-components';
import { screen } from '@styles/mixins/breakpoints';
import styles from './FactsAndFigures.styles';

const { StyledInformationList } = styles;

const FactsAndFigures = () => {
  const aboutList = useStaticQuery(
    graphql`
      {
        allJsonJson {
          nodes {
            company_facts {
              id
              title
              list {
                imageUrl {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
                id
                title
                subTitle
              }
            }
          }
        }
      }
    `,
  );

  const { list: itemsList } = aboutList.allJsonJson.nodes.find(
    ({ company_facts }) => !!company_facts,
  ).company_facts;

  const informationList = useMemo(() => {
    const defaultProps = {
      listCssRules: css`
        width: 100%;
        max-width: 1088px;
        div[class*='StyledItemContent'] {
          display: flex;
          align-items: center;
        }
        div[class*='StyledItemContainer'] {
          &:nth-child(2n) {
            padding-left: 120px;
          }
          ${screen.l(css`
            &:nth-child(2n) {
              padding-left: 16px;
            }
          `)}
        }
      `,
      isImageLinkDisabled: true,
      list: itemsList,
    };

    return (
      <StyledInformationList>
        <InformationList {...defaultProps} />
      </StyledInformationList>
    );
  }, [itemsList]);

  const descriptionText = itemsList
    && !itemsList.length
    && itemsList[0].sections
      .find(({ type }) => type === 'text')
      .value.slice(0, 160);

  return (
    <>
      <Helmet>
        <meta name="description" content={descriptionText} />
        <meta itemProp="description" content={descriptionText} />
        <meta property="og:description" content={descriptionText} />
        <meta property="twitter:description" content={descriptionText} />
      </Helmet>
      <TextHeading title="Facts and figures" isInvisibleHeading />
      {informationList}
    </>
  );
};

export default FactsAndFigures;
